<template>  
  <div class="page-box">
    <div class='editInfo'>
      <label class='label'>
        <span class='labelTitle'>订单号</span>
        <div class="rowCenter item-cont">
          <input type='text' maxlength='50' placeholder="订单号" v-model="orderNo" />
          <img src="../assets/images/scan.png" mode="aspectFill" class="scan-btn" @click="OnQRcode" />
        </div>
      </label>
      <label class='label'>
        <span class='labelTitle'>开始时间</span>  
        <div class="rowCenter item-cont" @click="showStartTime = true">
          <input type='text' readonly placeholder="开始时间" :value='startTime' />
          <div class="more"></div>
        </div>   
        <van-popup v-model:show="showStartTime" round position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            @cancel="showStartTime = false"
            @confirm="onConfirmsStartTime"
          />
        </van-popup>
      </label>
      <label class='label'>
        <span class='labelTitle'>结束时间</span>  
        <div class="rowCenter item-cont" @click="showEndTime = true">
          <input type='text' readonly placeholder="结束时间" :value='endTime' />
          <div class="more"></div>
        </div>
        <van-popup v-model:show="showEndTime" round position="bottom">
          <van-datetime-picker
            v-model="currentDate1"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            @cancel="showEndTime = false"
            @confirm="onConfirmsEndTime"
          />
        </van-popup>
      </label>
      <label class='label'>
        <span class='labelTitle'>门店</span> 
        <div class="rowCenter item-cont" @click="showStore = true">
          <input type='text' readonly placeholder="门店" :value='shopName' />
          <div class="more"></div>
        </div>    
        <van-popup v-model:show="showStore" round position="bottom">
          <van-picker
            :columns="storeList"
            @cancel="showStore = false"
            @confirm="onConfirmStore"
          />
        </van-popup>  
      </label>
      <!-- <label class='label'>
        <span class='labelTitle'>终端</span>
        <div class="rowCenter item-cont" @click="showTerm = true">
          <input type='text' readonly placeholder="终端" :value='termName' />
          <div class="more"></div>
        </div>
        <van-popup v-model:show="showTerm" round position="bottom">
          <van-picker
            :columns="termList"
            @cancel="showTerm = false"
            @confirm="onConfirmTerm"
          />
        </van-popup> 
      </label> -->
    </div>
    
    <div class="opt-box">
      <div class='btn apply-btn center float-ani' @click="serchForm">查找</div>
    </div>  
  </div>
</template>

<script>
  import { getStoreListApi } from '@/api/store'
  import { getTermListApi } from '@/api/term'
  import { getOrderFlowApi, getFlowDetailApi } from '@/api/orderflow'
  import { parseTime } from '@/utils/index'
  import { Toast } from 'vant'
  import wx from "weixin-js-sdk"
  export default {
    name:'ordersearch1',
    data() {
      return {
        pageType:100,
        minDate: new Date(1970, 0, 1),
        orderNo:null,   //订单号
        startTime:null, //开始时间
        endTime:null,   //结束时间
        shopId:null,    //门店id
        shopName:null,    //门店name
        termId:null,    //终端id
        termName:null,    //终端name      
        index:0,
        index1:0,
        showStartTime:false,  //
        showEndTime:false,  //
        currentDate: new Date(),  //开始时间
        currentDate1: new Date(),  //结束时间
        showStore: false,   //是否显示门店列表弹窗
        storeList:[],   //门店列表
        showTerm:false,
        termList:[],    //终端列表  
      }
    },
    created(){
      const that = this;
      var { fparam } = that.$route['query'];  
      fparam = JSON.parse(fparam);
      that.orderNo = fparam.orderNo;
      that.startTime = fparam.startTime;
      that.endTime = fparam.endTime;
      if(fparam.startTime){
        that.currentDate = new Date(fparam.startTime);
      }
      if(fparam.endTime){
        that.currentDate1 = new Date(fparam.endTime);
      }
      that.shopId = fparam.shopId;
      that.shopName = fparam.shopName;
      that.termId = fparam.termId;
      that.termName = fparam.termName;

      that.getStoreList(); //获取门店列表
      that.getTermList();  //获取终端列表
    },
    methods: {
      //获取门店列表
      getStoreList(){
        const that = this;
        const param = {
          // startPage: false,          
        }
        getStoreListApi(param).then(res => {
          if(res && res.rows){
            let data = res.rows;
            for(let d of data){
              d.value = d.id;
              d.text = d.shopName;
            }
            that.storeList = data;            
          }          
        }); 
      },  
      //获取终端列表
      getTermList(){
        const that = this;
        const param = {
          // startPage: false,          
        }
        getTermListApi(param).then(res => {
          if(res && res.rows){
            let data = res.rows;
            for(let d of data){
              d.value = d.id;
              d.text = d.termName;
            }
            that.termList = data;            
          }          
        }); 
      },
      
      // 点击的时候调起扫一扫功能呢
      OnQRcode(){
        const that = this;
        wx.ready(function () { //通过ready接口处理成功验证
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
            success (res) {
              const resultStrArr = res.resultStr.split(',');
              // 转为数组时为了避免扫描一维码是返回CODE_128,20180528前面会添加一个CODE_128所以转为数组获		取最后一条就行了
              console.log(resultStrArr[resultStrArr.length - 1]); // 输出扫码信息
              // state.barcode = resultStrArr[resultStrArr.length - 1];
              that.orderNo = resultStrArr[resultStrArr.length - 1];
            },
            fail (res) {
              console.log('err', res);
            }
          });
        });
      },
      //选择门店
      onConfirmStore(value){
        const that = this;
        if(value){
          that.shopId = value.value;
          that.shopName = value.text;
        }
        that.showStore = false;
      },
      //选择终端
      onConfirmTerm(value){
        const that = this;
        if(value){
          that.termId = value.value;
          that.termName = value.text;
        }
        that.showTerm = false;
      },
      //选择开始日期
      onConfirmsStartTime(value){
        const that = this;
        if(value){
          that.startTime = parseTime(value,'{y}-{m}-{d}');
        }
        that.showStartTime = false;
      },
      //选择结束日期
      onConfirmsEndTime(value){
        const that = this;
        if(value){
          that.endTime = parseTime(value,'{y}-{m}-{d}');
        }
        that.showEndTime = false;
      },      
      //查找
      serchForm(){
        const that = this;
        //自定义筛选
        let formdata = {
          orderNo: that.orderNo,   //订单号
          startTime: that.startTime, //开始时间
          endTime: that.endTime,   //结束时间
          shopId: that.shopId,    //门店id
          shopName: that.shopName,    //门店name
          termId: that.termId,    //终端id
          termName: that.termName,    //终端name  
        }        
        localStorage.setItem('searchKeyword', JSON.stringify(formdata))
        this.$router.go(-1);
      },
    },
    mounted() {
      // this.$nextTick(() => {
      //   document.body.setAttribute('style', 'background:#ffffff')
      // })
    }
  }
</script>

<style lang="less" scoped >
  @import '../assets/style/mixin';
    
.page-box {
  font-size: 15px;
  background-color: #ffffff;
  min-height: 100vh;
}
.editInfo {
  /* padding-left: 28px; */
  line-height: 50px;
  margin-bottom: 13px;
  border-bottom: solid 1px #f2f2f2;
  border-top: solid 1px #f2f2f2;
}

.editInfo .label {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #f2f2f2;
  justify-content: space-between;
  padding-left: 1px;
  padding: 0 15px;
}

.editInfo .label:last-child {
  border: none;
}

.label .labelTitle {
  width: 100px;
  flex: none;
}
.item-cont{
  flex-grow:1;
  display: flex;
  justify-content: space-between;
}
.bind-picker{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 490px;
}
.label input {
  width: 200px;
  background-color: #FFFFFF;
}


.label .more {
  flex: none;
  width: 7px;
  height: 7px;
  border-top: 1px solid #888;
  border-right: 1px solid #888;
  transform: rotate(45deg);
}
.scan-btn{
  width: 18px;
  height: 18px;
}

.setDefault {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;
  border-bottom: solid 1px #f2f2f2;
  border-top: solid 1px #f2f2f2;
}

.editBut {
  text-align: center;
  line-height: 46px;
  margin: 15px auto;
  width: 80%;
  border-radius: 6px;
}

.submit {
  background-color: @primary;
  /* #53beb7;*/
  color: @primary;
  margin-top: 45px;
}

.delete {
  color: @primary;
}

.opt-box {
  height: 89px;
  padding-bottom: env(safe-area-inset-bottom);
}

.apply-btn {
  position: fixed;
  bottom: 15px;
  left: 4%;
  width: 92%;
  height: 44px;
  background: #FA3474;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: env(safe-area-inset-bottom);
}
</style>